/* You can add global styles to this file, and also import other style files */
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/regular";

* {
  font-family: "BancoDoBrasil Textos", sans-serif;
}

.title {
  font-family: "BancoDoBrasil Titulos", sans-serif;
}

.monospaced {
  font-family: Courier, monospace;
}

.blockui-white {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.surface-card {
  z-index: 1 !important;
}

.table-total {
  margin-bottom: 1em;
  cursor: default !important;
}

.p-datatable th {
  font-size: 0.7rem !important;

  .p-sortable-column-icon {
    font-size: 0.6rem !important;
    margin: 0 !important;
  }
}

.cursor-default {
  cursor: default !important;
}

.required:after {
  content: " *";
  color: tomato;
}

label.error,
div.error label,
div.error app-characters-remaining small,
small.error,
.field.error,
.ng-invalid.ng-dirty,
.p-inputtext.ng-dirty.ng-invalid,
p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button,
p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext,
p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  color: tomato !important;
  border-color: tomato;

  .p-button {
    border-color: tomato;
  }
}

app-register-product {
  .p-datatable > .p-datatable-wrapper {
    overflow: initial !important;
  }

  .p-tieredmenu {
    left: -126px !important;
  }
}

app-register-product-edit {
  .p-inputgroup .p-dropdown {
    width: 100% !important;

    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
      width: 100% !important;
    }
  }

  p-dropdown p-overlay .p-overlay {
    max-width: 200px;
  }

  .success-on-create-menu .p-menu {
    background:none;
    border:none;
    width: 100%;
  }


    .success-on-create-menu .p-menu .p-menu-list{
      display:flex;
      justify-content:space-between;

      a.p-menuitem-link{
        background:#1e4a89;
        border-radius:4px;
        box-shadow:inset 0 -2px 0 0 rgba(0, 0, 0, 0.5);
        color:#fff;

        span{
          color:#fff;
        }

        &:hover{
          background: #1e4a89;
          color: #fff;

          span{
            color:#fff;
          }
        }
      }
    }

  .draft span {
    color: #323130;
    background-color: #eeecec;
    cursor: default;
  }

  .available span {
    color: #ffffff;
    background-color: #498205;
    cursor: default;
  }

  .unavailable span {
    color: #ffffff;
    background-color: #d13438;
    cursor: default;
  }

  .draft-distribution span {
    color: #323130;
    background-color: #ffaa44;
    cursor: default;
  }
}

app-register-communication {
  .p-datatable > .p-datatable-wrapper {
    overflow: initial !important;
  }

  .p-tieredmenu {
    left: -126px !important;
  }
}

.p-selectbutton .p-button.p-highlight {
  background: #0078d4 !important;
  border-color: #605e5c;
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover {
  background: #4198da !important;
  border-color: #605e5c;
  color: #ffffff;
}

.p-colorpicker-panel {
  left: -161px !important;
}

.p-toast {
  z-index: 999999 !important;
}

.ow {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.small {
  font-size: small;
}

.p-button.p-button-inactive.p-button-outlined {
  color: #323130;
  border: 1px solid #323130;
}

.p-tabview {
  .p-tabview-nav {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-width: 0 0 2px 0;

    li {
      margin-right: 0;

      .p-tabview-nav-link {
        border: solid #dee2e6;
        border-width: 0 0 2px 0;
        border-color: transparent transparent #dee2e6 transparent;
        background: #ffffff;
        color: #6c757d;
        padding: 1.25rem;
        font-weight: 700;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        transition: box-shadow 0.2s;
        margin: 0 0 -2px 0;

        &:not(.p-disabled):focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.2rem #bfdbfe;
        }
      }

      &:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
        background: #ffffff;
        border-color: #adb5bd;
        color: #6c757d;
      }

      &.p-highlight .p-tabview-nav-link {
        background: #ffffff;
        border-color: #3b82f6;
        color: #3b82f6;
      }
    }
  }

  .p-tabview-left-icon {
    margin-right: 0.5rem;
  }

  .p-tabview-right-icon {
    margin-left: 0.5rem;
  }

  .p-tabview-close {
    margin-left: 0.5rem;
  }

  .p-tabview-nav-btn {
    &.p-link {
      background: #ffffff;
      color: #3b82f6;
      width: 3rem;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      border-radius: 0;

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.2rem #bfdbfe;
      }
    }
  }

  .p-tabview-panels {
    background: #ffffff;
    padding: 1.25rem;
    border: 0 none;
    color: #495057;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

.p-button-label {
  font-weight: normal;
}

.field label {
  font-weight: 600;
}

.field-checkbox label {
  font-weight: normal;
}

// style main old

.input-custom {
  .p-fileupload-choose {
    background: #1e4a89 !important;
    border: 0;
    //border-top: 2px solid #808080;
    font-size: 14px;
    font-weight: 400;
    padding: 0.5rem 1.75rem;
  }
}

.register-product {
  .p-tabview-panels {
    padding: 0 !important;
  }

  .p-tabview-nav-content ul {
    border: 0 !important;

    li {
      flex: 1;
      margin-top: 1.5rem;
      margin-bottom: 3rem;
    }
  }

  ul {
    //border: 0 !important;
    border-radius: 4px;

    .p-highlight {
      .btn-custom {
        background: #1761b7;

        color: #ffffff;
      }
    }

    li {
      //flex: 1;
      //margin-top: 1.5rem;
      //margin-bottom: 3rem;

      .btn-custom {
        width: 100%;
        border: 1px solid #1761b7;
        background: #fff;
        font-size: 14px;
        font-weight: 400;
        // border-radius: 0px 4px 4px 0px;
        padding: 1rem 1.75rem;
        text-transform: uppercase;
        color: #505050;
        text-align: center;
      }
    }
  }

  .p-tabview-nav-link {
    box-shadow: none !important;
    border: 0 !important;
    padding: 0 !important;
    border-radius: 0 !important;
    width: 100%;
  }

  //.col-12 {
  //  padding: 0 !important;
  //}
}

.p-datatable .p-datatable-header {
  background-color: transparent;
  padding: 0;
  border: none;
}

.p-paginator .p-paginator-current {
  border: none !important;
  color: #464646;
  margin-right: 2rem;
}

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
  //background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #0000EE;
  //min-width: 2.357rem;
  //height: 2.357rem;
  //margin: 0 0 0 -1px;
  //transition: box-shadow 0.15s;
  //border-radius: 0;
}

.p-paginator .p-paginator-first.p-disabled,
.p-paginator .p-paginator-prev.p-disabled,
.p-paginator .p-paginator-next.p-disabled,
.p-paginator .p-paginator-last.p-disabled {
  //background-color: #ffffff;
  //border: 1px solid #ffffff;
  color: #a09f9f !important;
  //font-weight: bold;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #464646;
  font-weight: bold;
}

.p-paginator .p-paginator-first {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-paginator .p-paginator-last {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #0000EE;
  //min-width: 2.357rem;
  //height: 2.357rem;
  //margin: 0 0 0 -1px;
  //transition: box-shadow 0.15s;
  //border-radius: 0;

  &:not(.p-highlight):hover {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    color: #464646;
    font-weight: bold;
  }
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #ffffff;
  border-color: #ffffff;
  color: #464646;
}


.p-datatable .p-datatable-thead > tr > th {
  cursor: default;
}

.p-datatable .p-sortable-column {
  cursor: pointer !important;
}

.p-datatable .p-sortable-column.p-highlight {
  color: #0000EE;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #0000EE;
}

.p-datatable .p-sortable-column.p-highlight:hover {
  color: #0000EE;
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: #0000EE;
}

.p-fileupload-choose {
  background: #1e4a89 !important;
  border-color: #1e4a89 !important;
  text-transform: uppercase !important;
  font-size: 14px;

  &:not(.p-disabled):hover {
    background: #28579a !important;
    color: #ffffff;
    border-color: #28579a;
  }
}

.p-button {
  background: #1e4a89;
  border-color: #1e4a89;
  text-transform: uppercase !important;
  font-size: 14px;

  &:not(.p-disabled):hover {
    background: #28579a;
    color: #ffffff;
    border-color: #28579a;
  }
}

.p-button {
  padding: 0.5rem 1.75rem;
}

.p-button.p-button-danger {
  background: #f0f0f0;;
  border-color: #f0f0f0;;
  text-transform: uppercase !important;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  padding: 0.5rem 1.75rem;

  &:not(.p-disabled):hover {
    background: #d5d4d4;
    color: #333333;
    border-color: #d5d4d4;
  }
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #1e4a89;
  //color: #ffffff;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #1e4a89;
  //color: #ffffff;
}

.p-selectbutton .p-button {
  padding: 0.5rem 0.75rem;
}
